<template>
  <!-- 游戏配置与调控--游戏列表 -->
  <div id="ListBox">
    <div class="TitleBox">
      <div p class="title">
        <!-- <p>游戏配置与调控--房间列表</p> -->
        <!-- <p class="back" @click="back">返回</p> -->
        <el-breadcrumb separator-class="el-icon-arrow-right" class="back">
          <el-breadcrumb-item :to="{ path: '/GameConfig' }">游戏配置</el-breadcrumb-item>
          <el-breadcrumb-item>房间列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="AddList">
        <el-button
                type="success"
                icon="el-icon-circle-plus-outline"
                round
                @click="addDialogVisible = true"
        >新增房间</el-button>
      </div>
    </div>
    <div class="TabelBox">
      <el-button type="primary" size="mini" style="margin-bottom: 8px" round @click="refresh()">刷新</el-button>
      <!-- 表格 -->
      <el-table :data="RoomList" style="width: 100%" border stripe v-loading="loading">
        <el-table-column label="GameID" prop="gameid"></el-table-column>
        <el-table-column label="场次编号" prop="areaid"></el-table-column>
        <el-table-column label="房间编号" sortable prop="roomid" width="120"></el-table-column>
        <el-table-column label="游戏玩法" sortable prop="gameModeName" width="120"></el-table-column>
        <el-table-column label="游戏场次" sortable prop="gameLevelName" width="120"></el-table-column>
        <el-table-column prop="is_open" sortable label="是否开放" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.is_open===0?'关闭':'开启'}}</span>
          </template>
<!--          <template slot-scope="scope">{{ scope.row.is_open | isopen }}</template>-->
        </el-table-column>
        <el-table-column label="状态" sortable prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0" style="color: red">已关闭</span>
            <span v-if="scope.row.status == 1" style="color: #24af08">已启用</span>
            <span v-if="scope.row.status == 2" style="color: #09f1a4">使用中</span>
            <span v-if="scope.row.status == 3" style="color: #d1c700">维护中</span>
            <span v-if="scope.row.status == 4" style="color: #0b8bf1">维护完成</span>
          </template>
        </el-table-column>
        <el-table-column label="在线人数" prop="online" width="80"></el-table-column>
        <el-table-column label="节点地址" prop="addr" width="190"></el-table-column>
        <el-table-column label="默认大厅" prop="default_lobby"></el-table-column>
        <el-table-column label="人数限制" sortable prop="player_limit" width="120"></el-table-column>
        <el-table-column label="桌子数量" sortable prop="table_count" width="120"></el-table-column>

        <!-- 表格操作按钮 -->
        <el-table-column fixed="right" label="操作" width="280" class="btns">
          <template slot-scope="scope">
<!--            <el-button size="mini" type="success" round @click="showBootDialog(scope.row)">机器人设置</el-button>-->
<!--            <el-button-->
<!--                    type="info"-->
<!--                    size="mini"-->
<!--                    round-->
<!--                    @click="stopBootById(scope.row)"-->
<!--            >{{scope.row.isEnable ? '停用机器人':'启用机器人'}}</el-button>-->
            <el-button size="mini" :disabled="scope.row.status == 1?false:true " round type="info" @click="stopUserById(scope.row)">维护</el-button>
            <el-button size="mini" type="primary" round @click="showEditDialog(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" round @click="removeUserById(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <div class="page">
        <!-- <span>每页10条,</span> -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-size="queryInfo.pagenum"
                :page-sizes="[20, 50, 100, 200,500]"
                style="float:right"
                layout="sizes,total, prev, pager, next, jumper"
                :total="total"
        ></el-pagination>
        <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
      </div>
      <!-- 添加游戏列表 -->
      <!-- 弹出新增房间对话框 -->
      <el-dialog
              :title="'新增房间'"
              style="text-align:left !important"
              :visible.sync="addDialogVisible"
              @close="addDialogClosed"
              :close-on-click-modal="false"
      >
        <!-- 内容 -->
        <el-form ref="addFormRef" :model="addForm" :rules="addFormRules">
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="6">
              <el-form-item label="GAMEID" prop="gameid" style="width:400px">
                <el-input v-model="addForm.gameid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="场次编号" prop="areaid" style="width:400px">
                <el-input v-model="addForm.areaid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="游戏玩法" prop="gameModeName" style="width:400px">
                <el-input v-model="addForm.gameModeName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="游戏场次" prop="gameLevelName" style="width:400px">
                <el-input v-model="addForm.gameLevelName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="6">
              <el-form-item label="房间编号" prop="roomid" style="width:400px">
                <el-input v-model="addForm.roomid"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否开放" prop="is_open">
                <el-select v-model="addForm.is_open" placeholder="是否开放">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态" prop="status">
                <el-select v-model="addForm.status" placeholder="状态">
                  <el-option label="已经关闭" value="0"></el-option>
                  <el-option label="启动服务器" value="1"></el-option>
                  <el-option label="正在维护" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="节点地址" prop="addr" style="width:400px">
                <el-input v-model="addForm.addr" placeholder></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="6">
              <el-form-item label="默认大厅" prop="default_lobby" style="width:400px">
                <el-input v-model="addForm.default_lobby" placeholder></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="人数限制" prop="player_limit" style="width:400px">
                <el-input v-model="addForm.player_limit" placeholder></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="桌子数量" prop="table_count" style="width:400px">
                <el-input v-model="addForm.table_count" placeholder></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 按钮区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="addList">提交</el-button>
          <el-button type="primary" @click="addDialogVisible = false">取消</el-button>
        </span>
      </el-dialog>

      <!-- 机器人设置 -->
      <!-- 弹框 -->
      <el-dialog
              :title="'机器人设置:'+this.gamename+'('+this.gameModeName+')'"
              style="text-align:left !important"
              :visible.sync="bootDialogVisible"
              @close="bootDialogClosed"
              :close-on-click-modal="false"
      >
        <!-- 内容 -->
        <el-form
                ref="bootFormRef"
                :rules="bootFormRules"
                :model="bootForm"
                label-position="left"
                :inline="true"
                label-width="150px"
        >
          <el-row type="flex">
            <el-col style="padding-bottom:10px !important">
              <!-- <el-form-item label="房间编号" prop="areaid"> -->
              <!-- <span>{{bootForm.areaid}}</span> -->
              <span style="font-size:18px;font-weight: 600">房间编号：</span>
              <span style="font-size:18px;">{{bootForm.roomid}}</span>
              <!-- </el-form-item> -->
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <h2 style="border-top:1px solid #000;padding:20px 0;color:red">已启用</h2>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12">
              <el-form-item label="已启用机器人批号:" prop="num">
                <span>{{isopen.batchid}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="机器人最大在线数量:"  prop="count">
                <span>{{isopen.max}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <h2 style="border-top:1px solid #000;padding:20px 0;color:red">更新</h2>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="8" style="display:flex;">
              <el-form-item label="机器人批次号" prop="batchid">
                <el-input v-model="bootForm.batchid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button
                      size="mini"
                      type="danger"
                      style="height:40px;"
                      round
                      @click="changeNum()"
              >更换批次号</el-button>
            </el-col>

            <el-col :span="8">
              <el-form-item label="该批次机器人最大数量" prop="gameMax">
                <el-input v-model.number="bootForm.gameMax" :value="bootForm.gameMax" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="8">
              <el-form-item label="机器人最大在线数量" prop="gameMax1">
                <el-input v-model.number="bootForm.gameMax1" :value="bootForm.gameMax1"></el-input>

              </el-form-item>
              <span style="line-height: 40px; color: red; font-weight: 600;">(提示：请填1-{{bootForm.gameMax}})</span>
            </el-col>
          </el-row>
        </el-form>
        <!-- 按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="bootList">提交</el-button>
          <el-button type="primary" @click="bootDialogVisible = false">取消</el-button>
        </span>
      </el-dialog>

      <!-- 编辑部分 -->
      <!-- 弹框 -->
      <el-dialog
              :title="'编辑房间列表'"
              style="text-align:left !important"
              :visible.sync="editDialogVisible"
              @close="editDialogClosed"
              :close-on-click-modal="false"
      >
        <!-- 内容 -->
        <el-form ref="editFormRef" :model="editForm" :rules="editFormRules">
          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="6">
              <el-form-item label="GAMEID" prop="gameid">
                <el-input v-model="editForm.gameid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="场次编号" prop="areaid" style="width:400px">
                <el-input v-model="editForm.areaid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="游戏玩法" prop="gamemode" style="width:400px">
                <el-input v-model="editForm.gameModeName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="游戏场次" prop="gamelevel" style="width:400px">
                <el-input v-model="editForm.gameLevelName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="6">
              <el-form-item label="房间编号" prop="roomid" style="width:400px">
                <el-input v-model="editForm.roomid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否开放" prop="is_open">
                <el-select v-model="editForm.is_open" placeholder="是否开放">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态" prop="status">
                <el-select v-model="editForm.status" placeholder="状态">
                  <el-option label="已经关闭" value="0"></el-option>
                  <el-option label="启动服务器" value="1"></el-option>
                  <el-option label="正在维护" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="节点地址" prop="addr" style="width:400px">
                <el-input v-model="editForm.addr" placeholder></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="6">
              <el-form-item label="默认大厅" prop="default_lobby" style="width:400px">
                <el-input v-model="editForm.default_lobby" placeholder></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="人数限制" prop="player_limit" style="width:400px">
                <el-input v-model="editForm.player_limit" placeholder></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="桌子数量" prop="table_count" style="width:400px">
                <el-input v-model="editForm.table_count" placeholder></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="editList">提交</el-button>
          <el-button type="primary" @click="editDialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { Encrypt, Decrypt } from "../../assets/js/utils";
  export default {
    data() {
      return {
        loading: true,
        gamename: this.$route.params.gamename,
        queryInfo: {
          areaid: this.$route.params.areaid,
          gameid: this.$route.params.gameid,
          // 当前页码
          page: 1,
          // 分页数
          // page: 1,
          // 每页显示条数
          pagenum: 20
        },
        total: 0,
        gameModeName: "",
        // 表格列表数据
        RoomList: [],
        // 控制添加游戏列表对话框显示与隐藏
        addDialogVisible: false,
        // 控制机器人设置对话框显示与隐藏
        bootDialogVisible: false,
        // 编辑游戏列表显示与隐藏
        editDialogVisible: false,
        // 添加表单数据
        addForm: {
          gameid: this.$route.params.gameid,
          gamemode: this.$route.params.gamemode,
          gamelevel: this.$route.params.gamelevel,
          gameLevelName: this.$route.params.gameLevelName,
          gameModeName: this.$route.params.gameModeName,
          areaid: this.$route.params.areaid,
          roomid: "",
          is_open: "",
          status: "",
          addr: "",
          default_lobby: "",
          player_limit: "",
          table_count: "",
          code: ""
        },
        // 添加验证表单对象
        addFormRules: {
          roomid: [{ required: true, message: "请输入房间ID", trigger: "blur" }],
          is_open: [
            { required: true, message: "请选择是否开放", trigger: "blur" }
          ],
          status: [{ required: true, message: "请选择状态", trigger: "blur" }],
          addr: [
            { required: true, message: "格式：127.0.0.1:8080", trigger: "blur" }
          ],
          default_lobby: [
            { required: true, message: "请填写默认大厅编号", trigger: "blur" }
          ],
          player_limit: [
            { required: true, message: "请填写最大人数限制", trigger: "blur" }
          ],
          table_count: [
            { required: true, message: "请填写桌子数量", trigger: "blur" }
          ]
        },
        // 编辑表单数据
        editForm: {
          gameid: "",
          gameLevelName: "",
          gameModeName: "",
          areaid: "",
          roomid: "",
          is_open: "",
          status: "",
          addr: "",
          default_lobby: "",
          player_limit: "",
          table_count: "",
          code: ""
        },
        // 编辑验证表单对象
        editFormRules: {
          is_open: [
            { required: true, message: "请选择是否开放", trigger: "blur" }
          ],
          status: [{ required: true, message: "请选择状态", trigger: "blur" }],
          addr: [
            { required: true, message: "格式：127.0.0.1：7700", trigger: "blur" }
          ],
          default_lobby: [
            { required: true, message: "请填写默认大厅编号", trigger: "blur" }
          ],
          player_limit: [
            { required: true, message: "请填写最大人数限制", trigger: "blur" }
          ],
          table_count: [
            { required: true, message: "请填写桌子数量", trigger: "blur" }
          ]
        },
        // 添加机器人设置表单数据
        bootForm: {
          batchid: "",
          roomid: "",
          gamename: "",
          gameMax: "",
          gameMax1:"30"
        },
        bootFormRules: {
          gameMax1: [
            { required: true, message: "请填写数量", trigger: "blur" },
            { type: "number", message: "必须为数字" }
          ]
        },
        isopen: {}
      };
    },
    mounted() {
      // 获取房间列表
      this.getRoomList();
    },
    methods: {
      change(newPage) {
        this.loading = false;
        this.handleCurrentChange(newPage);
        this.loading = true;
      },
      refresh(){
        this.loading = true;
        this.getRoomList();
      },
      // 获取房间列表
      getRoomList() {
        var params = this.queryInfo;
        let that = this;
        this.$Http({
          url: "/api/room/list",
          params,
          successful(res, data) {
            that.RoomList = data.data.data;
            that.total = data.data.total;
            that.loading = false;
          }
        });
      },
      // 分页器
      // 监听pageName改变的事件
      handleSizeChange(pagenum) {
        this.loading = false;
        this.queryInfo.pagenum = pagenum;
        this.getRoomList();
        this.loading = true;
      },
      // 监听页码值改变的事件
      handleCurrentChange(newPage) {
        this.loading = false;
        this.queryInfo.page = newPage;
        this.getRoomList();
        this.loading = true;
      },
      // 监听添加列表的对话框关闭事件
      addDialogClosed() {
        this.$refs.addFormRef.resetFields();
      },
      // 监听编辑列表对话框的关闭事件
      editDialogClosed() {
        this.$refs.editFormRef.resetFields();
      },
      // 监听机器人设置对话框的关闭事件
      bootDialogClosed() {
        this.$refs.bootFormRef.resetFields();
      },
      // 点击添加列表提交按钮添加列表
      addList() {
        // 获取游戏名称
        var params = this.addForm;
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return;
          //  添加请求
          let that = this;
          this.$Http({
            url: "/api/room/add",
            params,
            successful(res, data) {
              if (data.status == false) {
                that.$message.error(data.message);
              } else {
                if (data.data.code != 200) {
                  that.$message.error(data.data.message);
                  params.code = data.data.code;
                } else {
                  that.$message.success(data.data.message);
                  that.addDialogVisible = false;
                  that.getRoomList();
                  that.addDialogClosed();
                }
              }
            }
          });
        });
      },
      // 编辑按钮
      showEditDialog(row) {
        this.editForm.roomid = row.roomid;
        this.editForm.areaid = row.areaid;
        this.editForm.gameid = row.gameid;
        this.editForm.gameModeName = row.gameModeName;
        this.editForm.gameLevelName = row.gameLevelName;
        this.editForm.is_open = String(row.is_open);
        this.editForm.status = String(row.status);
        this.editForm.addr = row.addr;
        this.editForm.default_lobby = row.default_lobby;
        this.editForm.player_limit = row.player_limit;
        this.editForm.table_count = row.table_count;
        this.editDialogVisible = true;
      },
      // 编辑并提交信息
      editList() {
        var params = this.editForm;
        this.$refs.editFormRef.validate(valid => {
          if (!valid) return;
          //  添加请求
          let that = this;
          this.$Http({
            url: "/api/room/edit",
            params,
            successful(res, data) {
              if (data.status == false) {
                return that.$message.error(data.message);
              } else {
                if (data.code != 200) {
                  that.$message.error(data.message);
                  params.code = data.code;
                } else {
                  that.editDialogVisible = false;
                  that.getRoomList();
                  that.$message.success(data.message);
                }
              }
            }
          });
        });
      },
      // 停用部分
      stopUserById(row) {
        var params = {roomid:row.roomid,status:row.status};
        let that = this;
        // 根据弹框停用
        const confirmResult = this.$confirm("提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          message: "此操作将维护该游戏房间，是否继续？"
        })
          .then(() => {
            this.$Http({
              url: "/api/room/action",
              params,
              successful(res, data) {
                if (data.status == false) {
                  that.$message.error(data.message);
                } else {
                  if(data.data.d.code == 0){
                    that.$message.success(data.data.d.msg);
                  }else{
                    that.$message.success(data.data.d.msg);
                  }
                  that.getRoomList();
                }

              }
            });
          })
          .catch(() => {
            that.$message.info("已取消维护");
          });
      },
      // 停用机器人部分
      stopBootById(row) {
        let roomid = row.roomid;
        let isEnable = row.isEnable === 1 ? "停用" : "启用";
        var params = { isEnable, roomid };
        let that = this;
        // 根据弹框停用
        const confirmResult = this.$confirm("提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          message: "此操作将" + isEnable + "机器人，是否继续？"
        })
          .then(() => {
            this.$Http({
              url: "/api/robotsettings/isEnable",
              params,
              successful(res, data) {
                if (data.status != true) {
                  that.$message.error(data.message);
                } else {
                  // that.$message.success(isEnable + "成功");
                  that.$message.success(data.data);
                  that.getRoomList();
                }
              }
            });
          })
          .catch(() => {
            that.$message.info("已取消删除");
          });
      },
      // 删除部分
      removeUserById(row) {
        this.editForm.roomid = row.roomid;
        this.editForm.areaid = row.areaid;
        this.editForm.gameid = row.gameid;
        var params = this.editForm;
        let that = this;
        // 根据弹框删除
        const confirmResult = this.$confirm(
          "此操作将删除该房间，是否继续？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            this.$Http({
              url: "/api/room/del",
              params,
              successful(res, data) {
                if (data.code != 200) {
                  that.$message.error(data.message);
                } else {
                  that.$message.success("删除成功");
                  that.getRoomList();
                }
              }
            });
          })
          .catch(() => {
            that.$message.info("已取消删除");
          });
      },
      // 返回上一页
      back() {
        this.$router.go(-1); //返回上一层
      },
      // 机器人设置
      showBootDialog(row) {
        this.bootForm = row;
        this.gameModeName = row.gameModeName;
        this.bootForm.gamename = this.gamename;
        this.getmax();

        var params = this.bootForm;
        this.$set(params, "gameMax1", 30);
        let that = this;
        this.$Http({
          url: "/api/robotsettings/getBatchs",
          params,
          successful(res, data) {
            if (data.status != true) {
              that.$alert("当前未添加机器人批次，请返回游戏配置界面进行设置！", {
                confirmButtonText: "确定",
                callback: action => {
                  that.$router.push({ path: "GameConfig" });
                }
              });
            } else {
              that.bootDialogVisible = true;
              that.$set(that.bootForm, "batchid", data.data.batchid);
              that.$set(that.bootForm, "gameMax", data.data.gameMax);
              // that.bootForm.gameMax1 = data.data.gameMax;
            }
          }
        });
      },
      // 机器人设置更换所有批次号
      changeNum() {
        var params = this.bootForm;
        params;
        let that = this;
        this.$Http({
          url: "/api/robotsettings/getBatchs",
          params,
          successful(res, data) {
            that.bootForm.batchid = data.data.batchid;
            that.bootForm.gameMax = data.data.gameMax;
          }
        });
      },
      // 机器人设置提交信息
      bootList() {
        var params = this.bootForm;
        // console.log(params);
        // debugger
        this.$refs.bootFormRef.validate(valid => {
          if (!valid) return;
          //  添加请求
          let that = this;
          this.$Http({
            url: "/api/robotsettings/roomBatch",
            params,
            successful(res, data) {
              if (data.status != true) {
                that.$message.error(data.message);
              } else {
                that.$message.success(data.data);
                that.RoomList.is_open = 1;
                that.bootDialogVisible = false;
                that.getRoomList();
              }
            }
          });
        });
      },
      getmax() {
        var params = {
          roomid: this.bootForm.roomid
        };
        let that = this;
        this.$Http({
          url: "/api/robotsettings/getOldBatch",
          params,
          successful(res, data) {
            that.$set(that.isopen, "batchid", data.data.batchid);
            that.$set(that.isopen, "max", data.data.max);
          }
        });
      }
    }
  };
</script>

<style lang="less" scoped>

  #ListBox .TitleBox .title {
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }
  #ListBox .TitleBox {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  /deep/ .el-form-item__label {
    font-weight: 600 !important;
  }
  #ListBox .el-form /deep/ .el-input__inner {
    width: 135px!important;
  }
  #ListBox .TitleBox .AddList button {
    font-weight: 700;
    background: #6495ed;
    border: 1px solid grey;
    border-radius: 2px;
    padding: 5px;
    cursor: pointer;
    color: #f5f5f5;
  }
  #ListBox .TitleBox {
    justify-content: space-between;
    margin-bottom: 20px;
  }
</style>